import React from "react";
import { connect } from "react-redux";
import ResponsiveMedia from "utils/responsive_media";
import { HighlightText } from "components/highlightText";
import { Container, Box } from "components/style/layoutStyle";
import { useState } from "react";
import { useEffect } from "react";
import { SquareButton } from "components/button";

import { NoticeTitleWrapper, NoticeContentWrapper, NoticeTitle, NoticeDate, NoticeDesc, Content } from "./style";
import ConvertDate from "utils/convert_date";
import NextPrev from "./nextPrev";
import { navigate } from "gatsby-link";
import { HtmlText } from "components/htmlText";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

const Detail = ({ keyword, notiData, notiId }) => {
    const { i18n } = useTranslation();

    const { isMobile } = ResponsiveMedia();
    const [notice, setNotice] = useState([
        {
            id: "",
            date: "",
            title: "",
            cat: "",
            desc: ""
        }
    ]);

    const moveToNotice = () => {
        const path = i18n.language === "ko" ? "/support/notice" : `/${i18n.language}/support/notice`;

        navigate(path);
    };

    useEffect(() => {
        if (notiId && notiData) {
            notiData.map((item, index) => {
                if (item.node.id !== undefined && item.node.id === notiId) {
                    setNotice({
                        index: index,
                        id: item.node.id,
                        date: ConvertDate(item.node.date),
                        title: item.node.title,
                        cat: item.node.category,
                        desc: item.node.desc
                    });
                }
            });
        } else {
            // moveToPrev();
        }
    }, [notiId, notiData]);
    return (
        <Container>
            <Box flexDirection={"column"}>
                <NoticeTitleWrapper mobile={isMobile}>
                    <NoticeTitle>
                        {notice.title && (
                            <Content>
                                <HighlightText text={notice.title} keyword={keyword} />
                            </Content>
                        )}
                    </NoticeTitle>
                    <NoticeDate>{notice.date}</NoticeDate>
                </NoticeTitleWrapper>
                <NoticeContentWrapper mobile={isMobile}>
                    <NoticeDesc>
                        <Stack sx={{ "*": { marginBottom: "0" } }}>{notice.desc && <HtmlText text={notice.desc} />}</Stack>
                    </NoticeDesc>
                </NoticeContentWrapper>
                <NextPrev data={notiData} index={notice.index} keyword={keyword} />
                <Box margin={isMobile === "true" ? "32px 0 80px" : "40px 0 135px"} justifycontent={"center"}>
                    <SquareButton fontSize={"16px"} text={"목록"} onClickEvent={moveToNotice} />
                </Box>
            </Box>
        </Container>
    );
};

export default connect(
    (state) => ({
        keyword: state.support.search_keyword,
        notiData: state.support.notice_data,
        notiId: state.support.notice_id
    }),
    null
)(Detail);
