import React, { useEffect, useMemo } from "react";
import ResponsiveMedia from "utils/responsive_media";

import Seo from "components/seo";
import Top from "organisms/support/top";
import Detail from "organisms/support/detail";
import MobileNav from "organisms/support/mobileNav";
import { NOTICE_META, NOTICE_META_EN } from "../../../../constants/data/meta_data";
import LayoutSupport from "../../../../templates/layoutSupport";
import { SupportActions } from "redux/actions";
import OrganizeSupportData from "organisms/support/organizeSupportData";
import axios from "axios";
import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";

const DetailPage = ({ id }) => {
    const { isMobile } = ResponsiveMedia();
    const { setNoticeData } = OrganizeSupportData();

    useEffect(() => {
        axios.get(process.env.GATSBY_NOTICE_ENDPOINT).then((res) => {
            setNoticeData(res.data, "", "");
        });
    }, []);

    useEffect(() => {
        SupportActions.setNoticeId(id);
    }, [id]);

    const { i18n } = useTranslation();

    const seoData = useMemo(() => {
        if (i18n.language === "en") return NOTICE_META_EN;

        return NOTICE_META;
    }, [i18n.language]);

    return (
        <LayoutSupport>
            <Seo title={seoData.title} description={seoData.description} canonical={seoData.canonical} kakaoKeyword={seoData.kakao} />
            {isMobile === "true" ? <MobileNav title={"공지사항"} /> : <Top />}
            <Detail />
        </LayoutSupport>
    );
};

export default DetailPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
