import styled from "styled-components";
import { NoticeDivision } from "organisms/support/table/style";

export const NoticeTitleWrapper = styled.div`
    width: 100%;
    display: flex;

    ${(props) =>
        props.mobile
            ? `
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 40px 20px 0;
        > div {
            &:nth-child(1) {
                font-size: 20px;
                margin: 0 0 8px;
            }
            &:nth-child(2) {
                font-size: 14px;
            }
        }
    `
            : `
        border-top: solid 2px #1e1e1e;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 80px;
        border-bottom: solid 1px #ddd;
        padding: 0 40px;
    `};
`;

export const NoticeContentWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: solid 1px #ddd;

    > div {
        ${(props) =>
            props.mobile
                ? `
            padding: 40px 20px 80px;
        `
                : `
            padding: 50px 40px 70px;
        `};
    }
`;

export const NoticeTitle = styled.div`
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.92;
    letter-spacing: -0.72px;
    text-align: left;
    color: #1e1e1e;
`;

export const NoticeDate = styled.div`
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: -0.13px;
    text-align: left;
    color: #707070;
`;

export const NoticeDesc = styled.div`
    width: 100%;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    text-align: left;
    color: #1e1e1e;
`;

export const NextPrevBox = styled.div`
    width: 100%;
    min-height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px #ddd;
`;

export const NextPrevWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    margin: 0 40px;

    > div {
        ${(props) =>
            props.mobile
                ? `
            &:nth-child(1){
                font-size: 12px;
                color: #999;
            }
            &:nth-child(2){
                display: none;
            }
            &:nth-child(3){
                padding: 0 20px 0;
                font-size: 14px;
            }
            &:nth-child(4){
                display: none;
            }
        `
                : `
        
        `}
    }
`;

export const NextPrevText = styled.div`
    min-width: 30px;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.39px;
    text-align: left;
    color: #1e1e1e;
`;

export const Division = styled(NoticeDivision)`
    min-width: 64px;
    margin-left: 80px;
    margin-right: 21px;
`;

export const NextPrevTitle = styled.div`
    width: 100%;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.39px;
    text-align: left;
    color: #1e1e1e;
`;

export const NextPrevDate = styled.div`
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: -0.13px;
    text-align: right;
    color: #707070;
`;

export const Content = styled.p`
    margin: 0;
    padding: 0;
    white-space: pre-line;
`;
