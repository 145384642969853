import React, { useMemo } from "react"
import styled from "styled-components"

export const HtmlText = ({ text }) => {
    const html = useMemo(() => {
        return { __html: text }
    }, [text])
    
    if (text === undefined) return ""
    return <div dangerouslySetInnerHTML={html}></div>
}
