import React, { useState, useEffect } from "react";
import { SupportActions } from "redux/actions";
import ResponsiveMedia from "utils/responsive_media";
import { HighlightText } from "components/highlightText";
import ConvertDate from "utils/convert_date";
import { navigate } from "gatsby-link";
import { NextPrevBox, NextPrevWrapper, NextPrevText, Division, NextPrevTitle, NextPrevDate } from "./style";
import { useTranslation } from "react-i18next";

const NextPrev = ({ data, index, keyword }) => {
    const { i18n } = useTranslation();

    const { isMobile } = ResponsiveMedia();
    const [prevNoti, setPrevNoti] = useState(null);
    const [nextNoti, setNextNoti] = useState(null);

    const setSelectedNoticeId = (id) => {
        SupportActions.setNoticeId(id);
    };

    useEffect(() => {
        if (data) {
            if (data.length > 1) {
                if (Number(index) > 0) {
                    setNextNoti(data[index - 1].node);
                } else {
                    setNextNoti(null);
                }

                if (Number(index) < data.length - 1) {
                    setPrevNoti(data[index + 1].node);
                } else {
                    setPrevNoti(null);
                }
            }
        }
    }, [index, data]);

    const moveToNoticeDetail = (id) => {
        const path = i18n.language === "ko" ? `/support/notice/detail/${id}` : `/${i18n.language}/support/notice/detail/${id}`;

        navigate(path);
    };

    return (
        <>
            {prevNoti && (
                <NextPrevBox>
                    <NextPrevWrapper mobile={isMobile}>
                        <NextPrevText>이전</NextPrevText>
                        <Division>
                            <HighlightText text={prevNoti.category} keyword={keyword} />
                        </Division>
                        <NextPrevTitle onClick={() => moveToNoticeDetail(prevNoti.id)} style={{ cursor: "pointer" }}>
                            <HighlightText text={prevNoti.title} keyword={keyword} />
                        </NextPrevTitle>
                        <NextPrevDate>{ConvertDate(prevNoti.date)}</NextPrevDate>
                    </NextPrevWrapper>
                </NextPrevBox>
            )}
            {nextNoti && (
                <NextPrevBox>
                    <NextPrevWrapper mobile={isMobile}>
                        <NextPrevText>다음</NextPrevText>
                        <Division>
                            <HighlightText text={nextNoti.category} keyword={keyword} />
                        </Division>
                        <NextPrevTitle onClick={() => moveToNoticeDetail(nextNoti.id)} style={{ cursor: "pointer" }}>
                            <HighlightText text={nextNoti.title} keyword={keyword} />
                        </NextPrevTitle>
                        <NextPrevDate>{ConvertDate(nextNoti.date)}</NextPrevDate>
                    </NextPrevWrapper>
                </NextPrevBox>
            )}
        </>
    );
};

export default NextPrev;
